.edit-inscription-form {
  .form-edit {
    text-align: left;

    .ant-form-item {
      margin-top: 3px;
      margin-bottom: 3px;

      i {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    tr.ant-table-row:hover > td {
      background: unset;
    }

    .disabled-row {
      background-color: rgb(243, 243, 243);
    }

    .btn-submit {
      margin-buttom: 20px;
      width: 100%;
    }
    .select-form {
      width: 100%;
      text-align: left;
    }
    label {
      text-align: left;
    }
    .control-label {
      color: black;
      text-align: left;
    }
    .control-required {
      color: red;
      text-align: left;
    }
  
  }
}
