body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edit-user-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px; }

.edit-user-form .form-edit {
  text-align: center; }
  .edit-user-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-user-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-user-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-user-form .form-edit .select-form {
    width: 100%;
    text-align: left; }

.menu-top {
  display: flex; }
  .menu-top button {
    color: black;
    padding-left: 0em; }
    .menu-top button:hover {
      color: #0098d3; }
    .menu-top button:focus, .menu-top button:active {
      color: #4ec0ec; }
  .menu-top__left {
    background-image: url(/static/media/lightblue.dbc5869c.png); }
    .menu-top__left-logo {
      width: 20%;
      padding: 0 10px 10px 10px; }
  .menu-top__pre_right {
    position: absolute;
    right: 0;
    width: 220px;
    padding-left: 30px;
    background: transparent; }
  .menu-top__right {
    position: absolute;
    right: 0;
    width: 60px; }

.admin-sider {
  background-image: url(/static/media/lightblue.dbc5869c.png);
  left: 0;
  height: 100vh;
  padding-top: 4%;
  border-bottom-right-radius: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 1; }
  .admin-sider__sider {
    position: fixed;
    left: 0;
    width: 50%;
    display: flex;
    padding: 0;
    height: 220px;
    background-color: white;
    border-bottom-right-radius: 50px; }
  .admin-sider .ant-menu {
    background-image: url(/static/media/lightblue.dbc5869c.png); }

.layout-init {
  background: white;
  padding: 0; }

#scroll-y {
  overflow-y: scroll;
  overflow-x: hidden; }

#scroll-x {
  overflow-x: scroll; }

#scroll {
  overflow: scroll; }

#scroll-b {
  scroll-behavior: initial; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

.side-nav {
  background-image: url(/static/media/lightblue.dbc5869c.png);
  padding-top: 1.5rem; }

.side-nav ul {
  margin: 0;
  padding: 0; }

.side-nav ul li a {
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block; }

.register-form {
  text-align: left; }
  .register-form .ant-form.item:nth-child(3), .register-form .ant-form.item:last-of-type {
    margin: 0; }
  .register-form__input .ant-input-prefix i {
    font-size: 1.375em; }
  .register-form__input input {
    font-size: 1.25em;
    padding: 2px 2px 2px 4px !important; }
    .register-form__input input:focus {
      border-color: #0098d3;
      box-shadow: none; }
  .register-form__button {
    width: 100%;
    font-size: 1.375em;
    background-color: #0098d3;
    color: #fff;
    height: 42px; }
    .register-form__button:hover {
      background-color: #4ec0ec;
      color: #fff; }
    .register-form__button:focus, .register-form__button:active {
      background-color: #0098d3;
      color: #fff;
      border: 0; }

.login-form .ant-form-item:last-of-type {
  margin: 0; }

.login-form__input .ant-input-prefix i {
  form-size: 1.375em; }

.login-form__input input {
  font-size: 1.25em;
  padding: 2px 2px 2px 4px !important; }
  .login-form__input input:focus {
    border-color: #0098d3;
    box-shadow: none; }

.login-form__button {
  width: 100%;
  font-size: 1.375em;
  background-color: #0098d3;
  color: #fff;
  height: 42px;
  margin-top: 20px; }
  .login-form__button:hover {
    background-color: #4ec0ec;
    color: #fff; }
  .login-form__button:focus, .login-form__button:active {
    background-color: #0098d3;
    color: #fff;
    border: 0; }

.login-form__recover {
  width: 100%;
  font-size: 1.375em;
  color: #0098d3;
  height: 42px;
  margin-top: 20px; }
  .login-form__recover:hover {
    color: blue; }
  .login-form__recover:focus, .login-form__recover:active {
    color: blue; }

.recover-form {
  text-align: left; }
  .recover-form .ant-form-item:last-of-type {
    margin: 0; }
  .recover-form__input .ant-input-prefix i {
    form-size: 1.375em; }
  .recover-form__input input {
    font-size: 1.25em;
    padding: 2px 2px 2px 4px !important; }
    .recover-form__input input:focus {
      border-color: #0098d3;
      box-shadow: none; }
  .recover-form__button {
    width: 100%;
    font-size: 1.375em;
    background-color: #0098d3;
    color: #fff;
    height: 42px;
    margin-top: 20px; }
    .recover-form__button:hover {
      background-color: #4ec0ec;
      color: #fff; }
    .recover-form__button:focus, .recover-form__button:active {
      background-color: #0098d3;
      color: #fff;
      border: 0; }
  .recover-form__recover {
    width: 100%;
    font-size: 1.375em;
    color: #0098d3;
    height: 42px;
    margin-top: 20px; }
    .recover-form__recover:hover {
      color: blue; }
    .recover-form__recover:focus, .recover-form__recover:active {
      color: blue; }
  .recover-form .control-label {
    width: 100%;
    color: black;
    text-align: left;
    font-size: 1.125em; }

.sign-in {
  background-image: url(/static/media/light.8a57a393.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .sign-in__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; }
    .sign-in__content-logo {
      padding: 10px 210px; }
      .sign-in__content-logo img {
        width: 100%; }
    .sign-in__content-tabs {
      background-color: #e8e8e8; }
      .sign-in__content-tabs .ant-tabs-nav {
        width: 100% !important; }
      .sign-in__content-tabs .ant-tabs-tab {
        display: block;
        flex: 1 1;
        text-align: center;
        font-size: 1.1875em;
        border-top: 4px solid #808090 !important;
        border-radius: 0 !important;
        margin: 0 !important; }
        @media (min-width: 992px) {
          .sign-in__content-tabs .ant-tabs-tab {
            width: 220px;
            font-size: 1.4375em; } }
        .sign-in__content-tabs .ant-tabs-tab:hover {
          color: #808088; }
        .sign-in__content-tabs .ant-tabs-tab:before {
          content: none; }
        .sign-in__content-tabs .ant-tabs-tab span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
      .sign-in__content-tabs .ant-tabs-nav > div:nth-of-type(1) {
        display: unset !important;
        width: 100% !important; }
      .sign-in__content-tabs .ant-tabs-nav-container {
        height: 60px !important; }
      .sign-in__content-tabs .ant-tabs-tab-active {
        color: #0098d3 !important;
        border-top: 4px solid #0098d3 !important;
        border-radius: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important; }
      .sign-in__content-tabs .ant-tabs-content {
        width: 100%;
        padding: 0 16px 16px 16px;
        text-align: center; }

.layout-init {
  background: white;
  padding: 0; }

.layout-admin {
  transition: margin-left 0.2s;
  margin: 0 auto; }
  .layout-admin__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
    background-image: url(/static/media/lightblue.dbc5869c.png);
    border-bottom-right-radius: 50px; }
  .layout-admin__sider {
    position: fixed;
    left: 0;
    width: 50%;
    display: flex;
    padding: 0;
    height: 220px;
    background-image: url(/static/media/lightblue.dbc5869c.png);
    border-bottom-right-radius: 50px; }
  .layout-admin__content {
    min-height: 88vh;
    padding: 90px 30px 0px 0px;
    background: white;
    overflow-y: visible;
    overflow-y: initial; }
  .layout-admin__footer {
    background-color: white;
    background-position: center center;
    bottom: 0;
    padding: 8px;
    width: 100%;
    text-align: center;
    size: 25px;
    color: gray;
    font-weight: bold;
    position: static;
    /*margin: 20px;*/ }

#scroll-y {
  overflow-y: auto;
  overflow-x: hidden; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

.side-nav {
  background-color: aqua;
  padding-top: 1.5rem; }

.side-nav ul {
  margin: 0;
  padding: 0; }

.side-nav ul li a {
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block; }

.reset-form {
  text-align: left; }
  .reset-form .ant-form-item:last-of-type {
    margin: 0; }
  .reset-form__input .ant-input-prefix i {
    font-size: 1.375em; }
  .reset-form__input input {
    font-size: 1.25em;
    padding: 2px 2px 2px 4px !important; }
    .reset-form__input input:focus {
      border-color: #0098d3;
      box-shadow: none; }
  .reset-form__button {
    width: 100%;
    font-size: 1.375em;
    background-color: #0098d3;
    color: #fff;
    height: 42px;
    margin-top: 20px;
    align-items: center; }
    .reset-form__button:hover {
      background-color: #4ec0ec;
      color: #fff; }
    .reset-form__button:focus, .reset-form__button:active {
      background-color: #0098d3;
      color: #fff;
      border: 0; }
  .reset-form__reset {
    width: 100%;
    font-size: 1.375em;
    color: #0098d3;
    height: 42px;
    margin-top: 20px; }
    .reset-form__reset:hover {
      color: blue; }
    .reset-form__reset:focus, .reset-form__reset:active {
      color: blue; }
  .reset-form .control-label {
    width: 100%;
    color: black;
    text-align: left;
    font-size: 1.125em; }

.reset-form {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .reset-form__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; }
    .reset-form__content-logo {
      margin-right: 40px; }
      .reset-form__content-logo img {
        width: 100%; }
    .reset-form__content-tabs {
      background-color: #e8e8e8; }
      .reset-form__content-tabs .ant-tabs {
        width: 90vw; }
        @media (min-width: 992px) {
          .reset-form__content-tabs .ant-tabs {
            width: auto; } }
      .reset-form__content-tabs .ant-tabs-nav-container {
        height: 60px !important; }
      .reset-form__content-tabs .ant-tabs-nav {
        width: 100%; }
      .reset-form__content-tabs .ant-tabs-tab {
        width: 50px;
        height: 60px !important;
        text-align: center;
        align-items: center;
        font-size: 1.1875em;
        border-top: 4px solid #808090 !important;
        border-radius: 0 !important;
        margin: 0 !important; }
        @media (min-width: 992px) {
          .reset-form__content-tabs .ant-tabs-tab {
            width: 220px;
            font-size: 1.4375em; } }
        .reset-form__content-tabs .ant-tabs-tab:hover {
          color: #808088; }
        .reset-form__content-tabs .ant-tabs-tab:before {
          content: none; }
        .reset-form__content-tabs .ant-tabs-tab span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
      .reset-form__content-tabs .ant-tabs-tab-active {
        color: #0098d3 !important;
        border-top: 4px solid #0098d3 !important;
        border-radius: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important; }
      .reset-form__content-tabs .ant-tabs-content {
        padding: 0 16px 16px 16px;
        text-align: center; }
  .reset-form__content-error {
    display: flex;
    text-align: center;
    color: black;
    font-size: large;
    align-items: center; }

.layout-basic {
  transition: margin-left 0.2s;
  background-image: url(/static/media/lightblue.dbc5869c.png); }
  .layout-basic__header {
    overflow: hidden;
    /* code added to prevent scroll */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
    background-image: url(/static/media/lightblue.dbc5869c.png); }
  .layout-basic__content {
    min-height: calc(100vh - 65px);
    padding: 90px 25px 25px 25px; }
  .layout-basic__footer {
    overflow: hidden;
    /* code added to prevent scroll */
    background-color: white;
    background-position: center center;
    background-size: cover;
    padding: 20px;
    width: 100%;
    text-align: center;
    size: 25px;
    color: gray;
    font-weight: bold;
    /*margin: 20px;*/ }

#scroll-y {
  overflow-y: auto;
  overflow-x: hidden; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

.add-user-form .form-add {
  text-align: left; }
  .add-user-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-user-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-user-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-user-form .form-add .select-form {
    text-align: left; }

.add-user-form .control-label {
  color: blue;
  text-align: left; }

.add-user-form .control-required {
  color: red;
  text-align: left; }

.list-users__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-users__header-switch {
    display: flex;
    align-items: center; }
    .list-users__header-switch > button {
      margin-right: 20px; }

.list-users .users-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-users .users-active .ant-list-item-meta {
    display: flex; }

.list-users .boton-export {
  align-items: right; }

.boton-user {
  margin-top: 5px;
  align-items: right; }

.button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.button-activate {
  margin: 6px;
  background-color: green;
  color: white; }


.edit-schoolyear-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 6px;
  margin-bottom: 10px; }

.edit-schoolyear-form .form-edit {
  text-align: left; }
  .edit-schoolyear-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-schoolyear-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-schoolyear-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-schoolyear-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-schoolyear-form .form-edit label {
    text-align: left; }
  .edit-schoolyear-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-schoolyear-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-schoolyear-form .form-add {
  text-align: left; }
  .add-schoolyear-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-schoolyear-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-schoolyear-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-schoolyear-form .form-add .select-form {
    text-align: left; }
  .add-schoolyear-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-schoolyear-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-schoolyears__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-schoolyears__header-switch {
    display: flex;
    align-items: center; }
    .list-schoolyears__header-switch > button {
      margin-right: 20px; }

.list-schoolyears__search {
  display: block;
  margin-bottom: 20px; }

.list-schoolyears .schoolyears-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-schoolyears .schoolyears-active .ant-list-item-meta {
    display: flex; }

.list-schoolyears .button-schoolyear {
  margin: 6px; }

.list-schoolyears .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-schoolyears .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-schoolyears .ant-table-footer {
  font-weight: bold; }


.edit-student-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px; }

.edit-student-form .form-edit {
  text-align: left; }
  .edit-student-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-student-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-student-form .form-edit .btn-view {
    margin-top: 20px;
    background-color: green;
    width: 100%; }
  .edit-student-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-student-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-student-form .form-edit label {
    text-align: left; }
  .edit-student-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-student-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-student-form .form-edit .btn-edit {
    margin-right: 20px; }

.add-student-form .form-add {
  text-align: left; }
  .add-student-form .form-add .ant-form-item {
    margin-top: 2px;
    margin-bottom: 3px; }
    .add-student-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-student-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-student-form .form-add label {
    text-align: left; }
  .add-student-form .form-add .select-form {
    text-align: left; }
  .add-student-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-student-form .form-add .control-required {
    color: red;
    text-align: left; }

.add-studentcycles-form .form-add {
  text-align: left; }
  .add-studentcycles-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-studentcycles-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-studentcycles-form .form-add .btn-add {
    margin-top: 20px;
    width: 100%; }
  .add-studentcycles-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-studentcycles-form .form-add .btn-view {
    margin-top: 20px;
    background-color: green;
    width: 100%; }
  .add-studentcycles-form .form-add label {
    text-align: left; }
  .add-studentcycles-form .form-add .select-form {
    text-align: left; }
  .add-studentcycles-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-studentcycles-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-students__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-students__header-switch {
    display: flex;
    align-items: center; }
    .list-students__header-switch > button {
      margin-right: 20px; }

.list-students__search {
  display: block; }

.list-students .students-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-students .students-active .ant-list-item-meta {
    display: flex; }

.list-students .button-courses {
  margin: 2px;
  background-color: blueviolet;
  color: white; }

.list-students .button-cycle {
  margin: 2px;
  background-color: yellowgreen;
  color: white; }

.list-students .button-enroll {
  margin: 2px;
  background-color: green;
  color: white; }

.list-students .button-student {
  margin: 2px; }

.list-students .button-activate {
  margin: 2px;
  background-color: green;
  color: white; }

.list-students .button-edit {
  margin: 2px;
  background-color: orange;
  color: white; }

.list-students .ant-table-footer {
  font-weight: bold; }

.list-students .inactive {
  pointer-events: none;
  position: fixed; }

.App {
  font-family: sans-serif;
  text-align: center; }

@-webkit-keyframes moveToRight {
  from {
    left: 0; }
  to {
    left: 100%; } }

@keyframes moveToRight {
  from {
    left: 0; }
  to {
    left: 100%; } }

.progress-outer {
  display: flex;
  align-items: center; }

.progress-info {
  width: 30px;
  padding-left: 5px; }

.progress {
  width: 100%;
  background: #eee;
  min-height: 1px;
  position: relative;
  overflow: hidden;
  border-radius: 3px; }

.progress.progress--small {
  height: 3px; }

.progress.progress--medium {
  height: 5px; }

.progress.progress--large {
  height: 10px; }

.progress-bar {
  background: lime;
  height: 100%;
  position: absolute;
  left: 0; }

.progress.progress--loading .progress-bar {
  width: 30%;
  -webkit-animation: moveToRight 1s infinite linear;
          animation: moveToRight 1s infinite linear;
  border-radius: 3%; }


.edit-specialty-form .form-edit {
  text-align: left; }
  .edit-specialty-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-specialty-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-specialty-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-specialty-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-specialty-form .form-edit label {
    text-align: left; }
  .edit-specialty-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-specialty-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-specialty-form .form-add {
  text-align: left; }
  .add-specialty-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-specialty-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-specialty-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-specialty-form .form-add label {
    text-align: left; }
  .add-specialty-form .form-add .select-form {
    text-align: left; }
  .add-specialty-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-specialty-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-specialties__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-specialties__header-switch {
    display: flex;
    align-items: center; }
    .list-specialties__header-switch > button {
      margin-right: 20px; }

.list-specialties__search {
  display: block;
  margin-bottom: 30px; }

.list-specialties .button-specialty {
  margin: 6px; }

.list-specialties .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-specialties .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-specialties .ant-table-footer {
  font-weight: bold; }

.edit-entrycondition-form .form-edit {
  text-align: left; }
  .edit-entrycondition-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-entrycondition-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-entrycondition-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-entrycondition-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-entrycondition-form .form-edit label {
    text-align: left; }
  .edit-entrycondition-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-entrycondition-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-entrycondition-form .form-add {
  text-align: left; }
  .add-entrycondition-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-entrycondition-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-entrycondition-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-entrycondition-form .form-add label {
    text-align: left; }
  .add-entrycondition-form .form-add .select-form {
    text-align: left; }
  .add-entrycondition-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-entrycondition-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-entryconditions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-entryconditions__header-switch {
    display: flex;
    align-items: center; }
    .list-entryconditions__header-switch > button {
      margin-right: 20px; }

.list-entryconditions__search {
  display: block;
  margin-bottom: 30px; }

.list-entryconditions .button-entrycondition {
  margin: 6px; }

.list-entryconditions .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-entryconditions .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-entryconditions .ant-table-footer {
  font-weight: bold; }

.edit-finalcondition-form .form-edit {
  text-align: left; }
  .edit-finalcondition-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-finalcondition-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-finalcondition-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-finalcondition-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-finalcondition-form .form-edit label {
    text-align: left; }
  .edit-finalcondition-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-finalcondition-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-finalcondition-form .form-add {
  text-align: left; }
  .add-finalcondition-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-finalcondition-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-finalcondition-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-finalcondition-form .form-add label {
    text-align: left; }
  .add-finalcondition-form .form-add .select-form {
    text-align: left; }
  .add-finalcondition-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-finalcondition-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-finalconditions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-finalconditions__header-switch {
    display: flex;
    align-items: center; }
    .list-finalconditions__header-switch > button {
      margin-right: 20px; }

.list-finalconditions__search {
  display: block;
  margin-bottom: 30px; }

.list-finalconditions .button-finalcondition {
  margin: 6px; }

.list-finalconditions .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-finalconditions .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-finalconditions .ant-table-footer {
  font-weight: bold; }

.edit-adviser-form .form-edit {
  text-align: left; }
  .edit-adviser-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-adviser-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-adviser-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-adviser-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-adviser-form .form-edit label {
    text-align: left; }
  .edit-adviser-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-adviser-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-adviser-form .form-add {
  text-align: left; }
  .add-adviser-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-adviser-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-adviser-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-adviser-form .form-add label {
    text-align: left; }
  .add-adviser-form .form-add .select-form {
    text-align: left; }
  .add-adviser-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-adviser-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-advisers__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-advisers__header-switch {
    display: flex;
    align-items: center; }
    .list-advisers__header-switch > button {
      margin-right: 20px; }

.list-advisers__search {
  display: block;
  margin-bottom: 30px; }

.list-advisers .button-adviser {
  margin: 6px; }

.list-advisers .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-advisers .button-pdf-complete {
  margin: 6px;
  background-color: blueviolet;
  color: white; }

.list-advisers .button-pdf-year {
  margin: 6px;
  background-color: greenyellow;
  color: white; }

.list-advisers .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-advisers .ant-table-footer {
  font-weight: bold; }

.edit-teacher-form .form-edit {
  text-align: left; }
  .edit-teacher-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-teacher-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-teacher-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-teacher-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-teacher-form .form-edit label {
    text-align: left; }
  .edit-teacher-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-teacher-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-teacher-form .form-add {
  text-align: left; }
  .add-teacher-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-teacher-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-teacher-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-teacher-form .form-add label {
    text-align: left; }
  .add-teacher-form .form-add .select-form {
    text-align: left; }
  .add-teacher-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-teacher-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-teachers__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-teachers__header-switch {
    display: flex;
    align-items: center; }
    .list-teachers__header-switch > button {
      margin-right: 20px; }

.list-teachers__search {
  display: block;
  margin-bottom: 30px; }

.list-teachers .button-teacher {
  margin: 6px; }

.list-teachers .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-teachers .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-teachers .ant-table-footer {
  font-weight: bold; }

#scroll-y {
  overflow-y: scroll; }

.edit-teachercollab-form .form-edit {
  text-align: left; }
  .edit-teachercollab-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-teachercollab-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-teachercollab-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-teachercollab-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-teachercollab-form .form-edit label {
    text-align: left; }
  .edit-teachercollab-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-teachercollab-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-teachercollab-form .form-add {
  text-align: left; }
  .add-teachercollab-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-teachercollab-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-teachercollab-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-teachercollab-form .form-add label {
    text-align: left; }
  .add-teachercollab-form .form-add .select-form {
    text-align: left; }
  .add-teachercollab-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-teachercollab-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-teachercollabs__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-teachercollabs__header-switch {
    display: flex;
    align-items: center; }
    .list-teachercollabs__header-switch > button {
      margin-right: 20px; }

.list-teachercollabs__search {
  display: block;
  margin-bottom: 30px; }

.list-teachercollabs .button-teachercollab {
  margin: 6px; }

.list-teachercollabs .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-teachercollabs .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-teachercollabs .ant-table-footer {
  font-weight: bold; }

.edit-statecourse-form .form-edit {
  text-align: left; }
  .edit-statecourse-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-statecourse-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-statecourse-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-statecourse-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-statecourse-form .form-edit label {
    text-align: left; }
  .edit-statecourse-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-statecourse-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-statecourse-form .form-add {
  text-align: left; }
  .add-statecourse-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-statecourse-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-statecourse-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-statecourse-form .form-add label {
    text-align: left; }
  .add-statecourse-form .form-add .select-form {
    text-align: left; }
  .add-statecourse-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-statecourse-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-statecourses__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-statecourses__header-switch {
    display: flex;
    align-items: center; }
    .list-statecourses__header-switch > button {
      margin-right: 20px; }

.list-statecourses__search {
  display: block;
  margin-bottom: 30px; }

.list-statecourses .button-statecourse {
  margin: 6px; }

.list-statecourses .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-statecourses .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-statecourses .ant-table-footer {
  font-weight: bold; }

.edit-typecourse-form .form-edit {
  text-align: left; }
  .edit-typecourse-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-typecourse-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-typecourse-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-typecourse-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-typecourse-form .form-edit label {
    text-align: left; }
  .edit-typecourse-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-typecourse-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-typecourse-form .form-add {
  text-align: left; }
  .add-typecourse-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-typecourse-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-typecourse-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-typecourse-form .form-add label {
    text-align: left; }
  .add-typecourse-form .form-add .select-form {
    text-align: left; }
  .add-typecourse-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-typecourse-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-typecourses__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-typecourses__header-switch {
    display: flex;
    align-items: center; }
    .list-typecourses__header-switch > button {
      margin-right: 20px; }

.list-typecourses__search {
  display: block;
  margin-bottom: 30px; }

.list-typecourses .button-typecourse {
  margin: 6px; }

.list-typecourses .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-typecourses .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-typecourses .ant-table-footer {
  font-weight: bold; }

.edit-department-form .form-edit {
  text-align: left; }
  .edit-department-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-department-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-department-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-department-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-department-form .form-edit label {
    text-align: left; }
  .edit-department-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-department-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-department-form .form-add {
  text-align: left; }
  .add-department-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-department-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-department-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-department-form .form-add label {
    text-align: left; }
  .add-department-form .form-add .select-form {
    text-align: left; }
  .add-department-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-department-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-departments__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-departments__header-switch {
    display: flex;
    align-items: center; }
    .list-departments__header-switch > button {
      margin-right: 20px; }

.list-departments__search {
  display: block;
  margin-bottom: 30px; }

.list-departments .button-department {
  margin: 6px; }

.list-departments .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-departments .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-departments .ant-table-footer {
  font-weight: bold; }

.edit-course-form .form-edit {
  text-align: left; }
  .edit-course-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-course-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-course-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-course-form .form-edit .ant-select-selection-item {
    background-color: white;
    color: black; }
  .edit-course-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-course-form .form-edit label {
    text-align: left; }
  .edit-course-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-course-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-course-form .form-add {
  text-align: left; }
  .add-course-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-course-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-course-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-course-form .form-add label {
    text-align: left; }
  .add-course-form .form-add .select-form {
    text-align: left; }
  .add-course-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-course-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-courses__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-courses__header-switch {
    display: flex;
    align-items: center; }
    .list-courses__header-switch > button {
      margin-right: 20px; }

.list-courses__search {
  display: block;
  margin-bottom: 30px; }

.list-courses .button-course {
  margin: 1px; }

.list-courses .button-enroll {
  margin: 1px;
  background-color: green;
  color: white; }

.list-courses .button-edit {
  margin: 1px;
  background-color: orange;
  color: white; }

.list-courses .button-activate {
  margin: 1px;
  background-color: green;
  color: white; }

.list-courses .button-attendance {
  margin: 1px;
  background-color: #6c6cdf;
  color: white; }

.list-courses .button-absences {
  margin: 1px;
  background-color: silver;
  color: white; }

.list-courses .ant-table-footer {
  font-weight: bold; }

.list-exports__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-exports__header-switch {
    display: flex;
    align-items: center; }
    .list-exports__header-switch > button {
      margin-right: 20px; }

.list-exports__search {
  display: block;
  margin-bottom: 30px; }

.list-exports .button-export {
  margin: 6px; }

.list-exports .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }


.list-exports__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-exports__header-switch {
    display: flex;
    align-items: center; }
    .list-exports__header-switch > button {
      margin-right: 20px; }

.list-exports__search {
  display: block;
  margin-bottom: 30px; }

.list-exports .button-export {
  margin: 6px; }

.list-exports .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-menu__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px; }

.list-menu__items {
  background-color: #fff;
  padding: 10px 20px; }
  .list-menu__items .item-button {
    margin: 6px; }

.list-menu .row-dragging {
  background: #fafafa;
  border: 1px solid #ccc; }

.list-menu .row-dragging td {
  padding: 16px; }

.list-menu .row-dragging .drag-visible {
  visibility: visible; }

.list-menu .ant-table-footer {
  font-weight: bold; }

.add-menu-form .form-add {
  text-align: left; }
  .add-menu-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-menu-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-menu-form .form-add .btn-submit {
    width: 100%; }
  .add-menu-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-menu-form .form-add .control-required {
    color: red;
    text-align: left; }

.edit-menu-form .form-edit {
  text-align: left; }
  .edit-menu-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-menu-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-menu-form .form-edit .btn-submit {
    width: 100%; }
  .edit-menu-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-menu-form .form-edit .control-required {
    color: red;
    text-align: left; }

.edit-role-form .form-edit {
  text-align: left; }
  .edit-role-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-role-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-role-form .form-edit .btn-submit {
    width: 100%; }
  .edit-role-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-role-form .form-edit label {
    text-align: left; }
  .edit-role-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-role-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-role-form .form-add {
  text-align: left; }
  .add-role-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-role-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-role-form .form-add .btn-submit {
    width: 100%; }
  .add-role-form .form-add label {
    text-align: left; }
  .add-role-form .form-add .select-form {
    text-align: left; }
  .add-role-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-role-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-roles__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-roles__header-switch {
    display: flex;
    align-items: center; }
    .list-roles__header-switch > button {
      margin-right: 20px; }

.list-roles__search {
  display: block;
  margin-bottom: 30px; }

.list-roles .button-role {
  margin: 6px; }

.list-roles .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-roles .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-roles .ant-table-footer {
  font-weight: bold; }

.edit-permission-form .form-edit {
  text-align: left; }
  .edit-permission-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-permission-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-permission-form .form-edit .btn-submit {
    width: 100%; }
  .edit-permission-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-permission-form .form-edit label {
    text-align: left; }
  .edit-permission-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-permission-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-permission-form .form-add {
  text-align: left; }
  .add-permission-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-permission-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-permission-form .form-add .btn-submit {
    width: 100%; }
  .add-permission-form .form-add label {
    text-align: left; }
  .add-permission-form .form-add .select-form {
    text-align: left; }
  .add-permission-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-permission-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-permissions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-permissions__header-switch {
    display: flex;
    align-items: center; }
    .list-permissions__header-switch > button {
      margin-right: 20px; }

.list-permissions__search {
  display: block;
  margin-bottom: 30px; }

.list-permissions .button-permission {
  margin: 6px; }

.list-permissions .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-permissions .ant-table-footer {
  font-weight: bold; }

.edit-person-form .upload-avatar {
  display: table;
  margin: 0 auto;
  border: 2px solid #9a9a9a;
  border-style: dashed;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px; }

.edit-person-form .form-edit {
  text-align: left; }
  .edit-person-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .edit-person-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-person-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-person-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-person-form .form-edit label {
    text-align: left; }
  .edit-person-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-person-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-person-form .form-add {
  text-align: left; }
  .add-person-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px; }
    .add-person-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-person-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-person-form .form-add label {
    text-align: left; }
  .add-person-form .form-add .select-form {
    text-align: left; }
  .add-person-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-person-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-persons__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-persons__header-switch {
    display: flex;
    align-items: center; }
    .list-persons__header-switch > button {
      margin-right: 20px; }

.list-persons__search {
  display: block;
  margin-bottom: 20px; }

.list-persons .persons-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-persons .persons-active .ant-list-item-meta {
    display: flex; }


.edit-day-form .form-edit {
  text-align: left; }
  .edit-day-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-day-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-day-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-day-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-day-form .form-edit label {
    text-align: left; }
  .edit-day-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-day-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-day-form .form-add {
  text-align: left; }
  .add-day-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-day-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-day-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-day-form .form-add label {
    text-align: left; }
  .add-day-form .form-add .select-form {
    text-align: left; }
  .add-day-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-day-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-days__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-days__header-switch {
    display: flex;
    align-items: center; }
    .list-days__header-switch > button {
      margin-right: 20px; }

.list-days__search {
  display: block;
  margin-bottom: 30px; }

.list-days .button-day {
  margin: 6px; }

.list-days .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-days .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-days .ant-table-footer {
  font-weight: bold; }

.edit-week-form .form-edit {
  text-align: left; }
  .edit-week-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-week-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-week-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-week-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-week-form .form-edit label {
    text-align: left; }
  .edit-week-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-week-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-week-form .form-add {
  text-align: left; }
  .add-week-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-week-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-week-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-week-form .form-add label {
    text-align: left; }
  .add-week-form .form-add .select-form {
    text-align: left; }
  .add-week-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-week-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-weeks__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-weeks__header-switch {
    display: flex;
    align-items: center; }
    .list-weeks__header-switch > button {
      margin-right: 20px; }

.list-weeks__search {
  display: block;
  margin-bottom: 30px; }

.list-weeks .button-week {
  margin: 6px; }

.list-weeks .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-weeks .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-weeks .ant-table-footer {
  font-weight: bold; }

.edit-place-form .form-edit {
  text-align: left; }
  .edit-place-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-place-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-place-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-place-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-place-form .form-edit label {
    text-align: left; }
  .edit-place-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-place-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-place-form .form-add {
  text-align: left; }
  .add-place-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-place-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-place-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-place-form .form-add label {
    text-align: left; }
  .add-place-form .form-add .select-form {
    text-align: left; }
  .add-place-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-place-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-places__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-places__header-switch {
    display: flex;
    align-items: center; }
    .list-places__header-switch > button {
      margin-right: 20px; }

.list-places__search {
  display: block;
  margin-bottom: 30px; }

.list-places .button-place {
  margin: 6px; }

.list-places .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-places .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-places .ant-table-footer {
  font-weight: bold; }

.edit-workload-form .form-edit {
  text-align: left; }
  .edit-workload-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-workload-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-workload-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-workload-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-workload-form .form-edit label {
    text-align: left; }
  .edit-workload-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-workload-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-workload-form .form-add {
  text-align: left; }
  .add-workload-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-workload-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-workload-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-workload-form .form-add label {
    text-align: left; }
  .add-workload-form .form-add .select-form {
    text-align: left; }
  .add-workload-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-workload-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-workloads__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-workloads__header-switch {
    display: flex;
    align-items: center; }
    .list-workloads__header-switch > button {
      margin-right: 20px; }

.list-workloads__search {
  display: block;
  margin-bottom: 30px; }

.list-workloads .button-workload {
  margin: 6px; }

.list-workloads .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-workloads .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-workloads .ant-table-footer {
  font-weight: bold; }

.edit-duration-form .form-edit {
  text-align: left; }
  .edit-duration-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-duration-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-duration-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-duration-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-duration-form .form-edit label {
    text-align: left; }
  .edit-duration-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-duration-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-duration-form .form-add {
  text-align: left; }
  .add-duration-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-duration-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-duration-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-duration-form .form-add label {
    text-align: left; }
  .add-duration-form .form-add .select-form {
    text-align: left; }
  .add-duration-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-duration-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-durations__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-durations__header-switch {
    display: flex;
    align-items: center; }
    .list-durations__header-switch > button {
      margin-right: 20px; }

.list-durations__search {
  display: block;
  margin-bottom: 30px; }

.list-durations .button-duration {
  margin: 6px; }

.list-durations .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-durations .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-durations .ant-table-footer {
  font-weight: bold; }

.edit-inscription-form .form-edit {
  text-align: left; }
  .edit-inscription-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-inscription-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-inscription-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-inscription-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-inscription-form .form-edit label {
    text-align: left; }
  .edit-inscription-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-inscription-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-inscription-form .form-add {
  text-align: left; }
  .add-inscription-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-inscription-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-inscription-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-inscription-form .form-add label {
    text-align: left; }
  .add-inscription-form .form-add .select-form {
    text-align: left; }
  .add-inscription-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-inscription-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-inscriptions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-inscriptions__header-switch {
    display: flex;
    align-items: center; }
    .list-inscriptions__header-switch > button {
      margin-right: 20px; }

.list-inscriptions__search {
  display: block;
  margin-bottom: 30px; }

.list-inscriptions .button-inscription {
  margin: 6px; }

.list-inscriptions .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-inscriptions .button-enroll {
  margin: 6px;
  background-color: green;
  color: white; }

.list-inscriptions .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-inscriptions .ant-table-footer {
  font-weight: bold; }

.add-absence-form .form-add {
  text-align: left; }
  .add-absence-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-absence-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-absence-form .form-add .ant-form-item .button-add {
      margin-top: 20px; }
  .add-absence-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-absence-form .form-add label {
    text-align: left; }
  .add-absence-form .form-add .select-form {
    text-align: left; }
  .add-absence-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-absence-form .form-add .control-required {
    color: red;
    text-align: left; }

.edit-absence-form .form-edit {
  text-align: left; }
  .edit-absence-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-absence-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .edit-absence-form .form-edit .ant-form-item .button-add {
      margin-top: 20px; }
  .edit-absence-form .form-edit .ant-select-selection-selected-value {
    width: "130%"; }
  .edit-absence-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-absence-form .form-edit label {
    text-align: left; }
  .edit-absence-form .form-edit .select-form {
    text-align: left; }
  .edit-absence-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-absence-form .form-edit .control-required {
    color: red;
    text-align: left; }

.list-absences__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-absences__header-switch {
    display: flex;
    align-items: center; }
    .list-absences__header-switch > button {
      margin-right: 20px; }

.list-absences__search {
  display: block;
  margin-bottom: 30px; }

.list-absences .button-absence {
  margin: 6px; }

.list-absences .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-absences .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-absences .ant-table-footer {
  font-weight: bold; }

.edit-inscription-form .form-edit {
  text-align: left; }
  .edit-inscription-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-inscription-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-inscription-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-inscription-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-inscription-form .form-edit label {
    text-align: left; }
  .edit-inscription-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-inscription-form .form-edit .control-required {
    color: red;
    text-align: left; }
  .edit-inscription-form .form-edit tr.ant-table-row:hover > td {
    background: unset; }
  .edit-inscription-form .form-edit .disabled-row {
    background-color: #f3f3f3; }

.list-inscriptions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-inscriptions__header-switch {
    display: flex;
    align-items: center; }
    .list-inscriptions__header-switch > button {
      margin-right: 20px; }

.list-inscriptions__search {
  display: block;
  margin-bottom: 30px; }

.list-inscriptions .button-inscription {
  margin: 6px; }

.list-inscriptions .ant-table-footer {
  font-weight: bold; }

.edit-inscription-form .form-edit {
  text-align: left; }
  .edit-inscription-form .form-edit .ant-form-item {
    margin-top: 3px;
    margin-bottom: 3px; }
    .edit-inscription-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-inscription-form .form-edit tr.ant-table-row:hover > td {
    background: unset; }
  .edit-inscription-form .form-edit .disabled-row {
    background-color: #f3f3f3; }
  .edit-inscription-form .form-edit .btn-submit {
    margin-buttom: 20px;
    width: 100%; }
  .edit-inscription-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-inscription-form .form-edit label {
    text-align: left; }
  .edit-inscription-form .form-edit .control-label {
    color: black;
    text-align: left; }
  .edit-inscription-form .form-edit .control-required {
    color: red;
    text-align: left; }

.list-inscriptions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-inscriptions__header-switch {
    display: flex;
    align-items: center; }
    .list-inscriptions__header-switch > button {
      margin-right: 20px; }

.list-inscriptions__search {
  display: block;
  margin-bottom: 30px; }

.list-inscriptions .button-inscription {
  margin: 6px; }

.list-inscriptions .ant-table-footer {
  font-weight: bold; }

.edit-stateschoolyear-form .form-edit {
  text-align: left; }
  .edit-stateschoolyear-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-stateschoolyear-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-stateschoolyear-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-stateschoolyear-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-stateschoolyear-form .form-edit label {
    text-align: left; }
  .edit-stateschoolyear-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-stateschoolyear-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-stateschoolyear-form .form-add {
  text-align: left; }
  .add-stateschoolyear-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-stateschoolyear-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-stateschoolyear-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-stateschoolyear-form .form-add label {
    text-align: left; }
  .add-stateschoolyear-form .form-add .select-form {
    text-align: left; }
  .add-stateschoolyear-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-stateschoolyear-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-stateschoolyears__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-stateschoolyears__header-switch {
    display: flex;
    align-items: center; }
    .list-stateschoolyears__header-switch > button {
      margin-right: 20px; }

.list-stateschoolyears__search {
  display: block;
  margin-bottom: 30px; }

.list-stateschoolyears .button-stateschoolyear {
  margin: 6px; }

.list-stateschoolyears .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-stateschoolyears .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-stateschoolyears .ant-table-footer {
  font-weight: bold; }

.edit-doctype-form .form-edit {
  text-align: left; }
  .edit-doctype-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-doctype-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-doctype-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-doctype-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-doctype-form .form-edit label {
    text-align: left; }
  .edit-doctype-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-doctype-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-doctype-form .form-add {
  text-align: left; }
  .add-doctype-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-doctype-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-doctype-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-doctype-form .form-add label {
    text-align: left; }
  .add-doctype-form .form-add .select-form {
    text-align: left; }
  .add-doctype-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-doctype-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-doctypes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-doctypes__header-switch {
    display: flex;
    align-items: center; }
    .list-doctypes__header-switch > button {
      margin-right: 20px; }

.list-doctypes__search {
  display: block;
  margin-bottom: 30px; }

.list-doctypes .button-doctype {
  margin: 6px; }

.list-doctypes .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-doctypes .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-doctypes .ant-table-footer {
  font-weight: bold; }

.edit-statestudentcourse-form .form-edit {
  text-align: left; }
  .edit-statestudentcourse-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-statestudentcourse-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-statestudentcourse-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-statestudentcourse-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-statestudentcourse-form .form-edit label {
    text-align: left; }
  .edit-statestudentcourse-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-statestudentcourse-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-statestudentcourse-form .form-add {
  text-align: left; }
  .add-statestudentcourse-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-statestudentcourse-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-statestudentcourse-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-statestudentcourse-form .form-add label {
    text-align: left; }
  .add-statestudentcourse-form .form-add .select-form {
    text-align: left; }
  .add-statestudentcourse-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-statestudentcourse-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-statestudentcourses__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-statestudentcourses__header-switch {
    display: flex;
    align-items: center; }
    .list-statestudentcourses__header-switch > button {
      margin-right: 20px; }

.list-statestudentcourses__search {
  display: block;
  margin-bottom: 30px; }

.list-statestudentcourses .button-statestudentcourse {
  margin: 6px; }

.list-statestudentcourses .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-statestudentcourses .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-statestudentcourses .ant-table-footer {
  font-weight: bold; }

.edit-inscription-form .form-edit {
  text-align: left; }
  .edit-inscription-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-inscription-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-inscription-form .form-edit .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .edit-inscription-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-inscription-form .form-edit label {
    text-align: left; }
  .edit-inscription-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-inscription-form .form-edit .control-required {
    color: red;
    text-align: left; }

.list-studentcourses__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-studentcourses__header-switch {
    display: flex;
    align-items: center; }
    .list-studentcourses__header-switch > button {
      margin-right: 20px; }

.list-studentcourses__search {
  display: block;
  margin-bottom: 30px; }

.list-studentcourses .button-inscription {
  margin: 6px; }

.list-studentcourses .button-edit {
  margin: 6px;
  color: white;
  background-color: orangered; }

.list-studentcourses .ant-table-footer {
  font-weight: bold; }

.edit-studentcondition-form .form-edit {
  text-align: left; }
  .edit-studentcondition-form .form-edit .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .edit-studentcondition-form .form-edit .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .edit-studentcondition-form .form-edit .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .edit-studentcondition-form .form-edit .select-form {
    width: 100%;
    text-align: left; }
  .edit-studentcondition-form .form-edit label {
    text-align: left; }
  .edit-studentcondition-form .form-edit .control-label {
    color: blue;
    text-align: left; }
  .edit-studentcondition-form .form-edit .control-required {
    color: red;
    text-align: left; }

.add-studentcondition-form .form-add {
  text-align: left; }
  .add-studentcondition-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-studentcondition-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
  .add-studentcondition-form .form-add .btn-submit {
    margin-top: 10px;
    width: 100%; }
  .add-studentcondition-form .form-add label {
    text-align: left; }
  .add-studentcondition-form .form-add .select-form {
    text-align: left; }
  .add-studentcondition-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-studentcondition-form .form-add .control-required {
    color: red;
    text-align: left; }

.list-studentconditions__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-studentconditions__header-switch {
    display: flex;
    align-items: center; }
    .list-studentconditions__header-switch > button {
      margin-right: 20px; }

.list-studentconditions__search {
  display: block;
  margin-bottom: 30px; }

.list-studentconditions .button-studentcondition {
  margin: 6px; }

.list-studentconditions .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-studentconditions .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-studentconditions .ant-table-footer {
  font-weight: bold; }

.add-absence-form .form-add {
  text-align: left; }
  .add-absence-form .form-add .ant-form-item {
    margin-top: 5px;
    margin-bottom: 8px; }
    .add-absence-form .form-add .ant-form-item i {
      color: rgba(0, 0, 0, 0.25); }
    .add-absence-form .form-add .ant-form-item .button-add {
      margin-top: 20px; }
  .add-absence-form .form-add .btn-submit {
    margin-top: 15px;
    width: 100%; }
  .add-absence-form .form-add label {
    text-align: left; }
  .add-absence-form .form-add .select-form {
    text-align: left; }
  .add-absence-form .form-add .control-label {
    color: blue;
    text-align: left; }
  .add-absence-form .form-add .control-required {
    color: red;
    text-align: left; }

.search-date {
  display: flex; }
  .search-date > button {
    width: 100%;
    margin-bottom: 6px; }
  .search-date .button-search {
    left: -6px; }
  .search-date .button-search-modal {
    padding: 6px; }

.list-courseabsences__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-courseabsences__header-switch {
    display: flex;
    align-items: center; }
    .list-courseabsences__header-switch > button {
      margin-right: 20px; }

.list-courseabsences__search {
  display: block;
  margin-bottom: 30px; }

.list-courseabsences .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-courseabsences .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-courseabsences .button-download {
  margin: 6px;
  background-color: rgba(81, 106, 245, 0.979);
  color: white; }

.list-courseabsences .ant-table-footer {
  font-weight: bold; }

.list-teachers__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-teachers__header-switch {
    display: flex;
    align-items: center; }
    .list-teachers__header-switch > button {
      margin-right: 20px; }

.list-teachers__search {
  display: block;
  margin-bottom: 30px; }

.list-teachers .button-teacher {
  margin: 6px; }

.list-teachers .button-cycle {
  margin: 2px;
  background-color: yellowgreen;
  color: white; }

.list-teachers .button-courses {
  margin: 2px;
  background-color: #b213de;
  color: white; }

.list-teachers .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-teachers .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-teachers .button-download {
  margin: 6px; }

.list-teachers .ant-table-footer {
  font-weight: bold; }

#scroll-y {
  overflow-y: scroll; }

.list-teachercollabs__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-teachercollabs__header-switch {
    display: flex;
    align-items: center; }
    .list-teachercollabs__header-switch > button {
      margin-right: 20px; }

.list-teachercollabs__search {
  display: block;
  margin-bottom: 30px; }

.list-teachercollabs .button-teachercollab {
  margin: 6px; }

.list-teachercollabs .button-cycle {
  margin: 2px;
  background-color: yellowgreen;
  color: white; }

.list-teachercollabs .button-edit {
  margin: 6px;
  background-color: orange;
  color: white; }

.list-teachercollabs .button-activate {
  margin: 6px;
  background-color: green;
  color: white; }

.list-teachercollabs .ant-table-footer {
  font-weight: bold; }

.list-students__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }
  .list-students__header-switch {
    display: flex;
    align-items: center; }
    .list-students__header-switch > button {
      margin-right: 20px; }

.list-students__search {
  display: block;
  margin-bottom: 20px; }

.list-students .students-active {
  background-color: #fff;
  padding: 10px 20px; }
  .list-students .students-active .ant-list-item-meta {
    display: flex; }

.list-students .button-cycle {
  margin: 2px;
  background-color: yellowgreen;
  color: white; }

.list-students .button-adviser {
  margin: 2px;
  background-color: firebrick;
  color: white; }

.list-students .button-hours {
  margin: 2px;
  background-color: blueviolet;
  color: white; }

.list-students .button-activitiesandhours {
  margin: 2px;
  background-color: blue;
  color: white; }

.list-students .button-edit {
  margin: 2px;
  background-color: orange;
  color: white; }

.list-students .ant-table-footer {
  font-weight: bold; }

.list-students .inactive {
  pointer-events: none;
  position: fixed; }

.App {
  font-family: sans-serif;
  text-align: center; }

@-webkit-keyframes moveToRight {
  from {
    left: 0; }
  to {
    left: 100%; } }

@keyframes moveToRight {
  from {
    left: 0; }
  to {
    left: 100%; } }

.progress-outer {
  display: flex;
  align-items: center; }

.progress-info {
  width: 30px;
  padding-left: 5px; }

.progress {
  width: 100%;
  background: #eee;
  min-height: 1px;
  position: relative;
  overflow: hidden;
  border-radius: 3px; }

.progress.progress--small {
  height: 3px; }

.progress.progress--medium {
  height: 5px; }

.progress.progress--large {
  height: 10px; }

.progress-bar {
  background: lime;
  height: 100%;
  position: absolute;
  left: 0; }

.progress.progress--loading .progress-bar {
  width: 30%;
  -webkit-animation: moveToRight 1s infinite linear;
          animation: moveToRight 1s infinite linear;
  border-radius: 3%; }


.App h1 {
  color: #0098d3; }

.App h2 {
  color: green; }

.App span {
  font-weight: 100; }

.success {
  border: 1px solid black;
  background-color: #50e2011f; }

.error {
  border: 1px solid #f00;
  background-color: #ff00001f; }

#scroll-y {
  overflow-y: auto;
  overflow-x: hidden; }

#scroll-hidden {
  overflow-y: hidden;
  overflow-x: hidden; }

