@import '../../../scss/index.scss';

.menu-top {
    display: flex;
    //background-image: url('../../../assets/img/png/lightblue.png');

    button {
        //background-image: url('../../../assets/img/png/light.png');
        color: black;
        padding-left: 0em;
        //border: 0;

        &:hover {
            color: $primary-color;
        }
        &:focus,
        &:active {
            color: $primary-color-hover;
        }
    }

    &__left {
        &-logo {
            width: 20%;
            padding: 0 10px 10px 10px;
        }
        background-image: url('../../../assets/img/png/lightblue.png');
    }

    &__pre_right {
        position: absolute;
        right: 0;
        //display: inline;
        width:220px;
        padding-left: 30px;
        background: transparent;
        // background-image: url('../../../assets/img/png/azul3.png');
    }

    &__right {
        position: absolute;
        right: 0;
        width:60px;
    }

}