.edit-week-form {
    .form-edit {
      text-align: left;
  
      .ant-form-item {
        margin-top: 5px;
        margin-bottom: 8px;
  
        i {
          color: rgba(0, 0, 0, 0.25);
        }
      }
  
      .btn-submit {
        margin-top: 10px;
        width: 100%;
      }
      .select-form {
        width: 100%;
        text-align: left;
      }
      label {
        text-align: left;
      }
      .control-label {
        color: blue;
        text-align: left;
      }
      .control-required {
        color: red;
        text-align: left;
      }
    }
  }
  