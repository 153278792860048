.list-teachers {
    //overflow-wrap: normal;  /* code added to prevent scroll */

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    &__search{
        display:block;
        margin-bottom: 30px;
    }

    .button-teacher{
        margin: 6px;
    }

    .button-cycle{
        margin: 2px;
        background-color: yellowgreen;
        color: white;
    }

    .button-courses{
        margin: 2px;
        background-color: rgb(178, 19, 222);
        color: white;
    }
    
    .button-edit{
        margin: 6px;
        background-color: orange;
        color: white;
    }
    .button-activate{
        margin: 6px;
        background-color: green;
        color: white;
    }
    .button-download{
        margin: 6px;
        // background-color: blue;
        // color: white;
    }


    .ant-table-footer{
        font-weight: bold;
    }
}

#scroll-y {
    overflow-y: scroll;
}