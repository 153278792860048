.add-studentcycles-form {
  .form-add {
    text-align: left;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 5px;

      i {
        color: rgba(0, 0, 0, 0.25);
      }

    }

    .btn-add {
      margin-top: 20px;
      width: 100%;
    }

    .btn-submit {
      margin-top: 15px;
      width: 100%;
    }

    .btn-view {
      margin-top: 20px;
      background-color: green;
      width: 100%;
    }

    label {
      text-align: left;
    }
    .select-form {
      text-align: left;
    }
    .control-label {
      color: blue;
      text-align: left;
    }
    .control-required {
      color: red;
      text-align: left;
    }
  }
}
