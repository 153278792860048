.list-students {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    &__search{
        display:block;
        // margin-bottom: 5px;
    }

    .students-active {
        background-color: #fff;
        padding: 10px 20px;

        .ant-list-item-meta {
            display: flex;
        }
    }

    .button-courses{
        margin: 2px;
        background-color: blueviolet;
        color: white;
    }
    .button-cycle{
        margin: 2px;
        background-color: yellowgreen;
        color: white;
    }
    .button-enroll{
        margin: 2px;
        background-color: green;
        color: white;
    }
    .button-student{
        margin: 2px;
    }
    .button-activate{
        margin: 2px;
        background-color: green;
        color: white;
    }
    .button-edit{
        margin: 2px;
        background-color: orange;
        color: white;
    }
    .ant-table-footer{
        font-weight: bold;
    }
    .inactive {
        pointer-events: none; // pointer-events not supported below IE11
        position: fixed;
    }
}