@import '../scss/index.scss';

.layout-basic {
    transition: margin-left 0.2s;
    background-image: url('../assets/img/png/lightblue.png');

    &__header {
        overflow: hidden;  /* code added to prevent scroll */
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 0;
        height: 65px;
        background-image: url('../assets/img/png/lightblue.png');
    }

    &__content {
        //background-image: url('../assets/img/png/azul3.png');
        min-height: calc(100vh - 65px);
        padding: 90px 25px 25px 25px;
    }

    //FOOTER DE LA APP
    &__footer {
        overflow: hidden;  /* code added to prevent scroll */
        //background-image: url('../assets/img/png/azul3.png');
        background-color: white;
        //background-image: url('../assets/img/png/lightblue.png');
        background-position: center center;
        background-size: cover;
        padding: 20px;
        width: 100%;
        text-align: center;
        size: 25px;
        color: gray;
        font-weight: bold;
        /*margin: 20px;*/
    }
}

#scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}

#scroll-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
}