.list-inscriptions {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-switch {
            display: flex;
            align-items: center;

            > button {
                margin-right: 20px;
            }
        }
    }

    &__search{
        display:block;
        margin-bottom: 30px;
    }

    .button-inscription{
        margin: 6px;
    }

    .button-edit{
        margin: 6px;
        background-color: orange;
        color: white;
    }

    .button-enroll{
        margin: 6px;
        background-color: green;
        color: white;
    }

    .button-activate{
        margin: 6px;
        background-color: green;
        color: white;
    }

    .ant-table-footer{
        font-weight: bold;
    }
}