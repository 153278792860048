@import '../../../scss/index.scss';

.admin-sider {
    background-image: url('../../../assets/img/png/lightblue.png');
    left: 0;
    //top: 65px;
    height: 100vh;
    //height: min-content;
    padding-top: 4%;
    border-bottom-right-radius: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 1;


    &__sider {
        position: fixed;
        left: 0;
        width: 50%;
        display: flex;
        padding: 0;
        height: 220px;
        background-color: white;
        border-bottom-right-radius: 50px;
    }

    .ant-menu{
        background-image: url('../../../assets/img/png/lightblue.png');
    }
}

.layout-init{
    background: white;
    padding: 0;
}

#scroll-y {
    overflow-y: scroll;
    overflow-x: hidden;
}

#scroll-x {
    overflow-x: scroll;
}

#scroll {
    overflow: scroll;
}

#scroll-b {
    scroll-behavior: initial;
}

#scroll-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
}

// Side Navigation
.side-nav {
    background-image: url('../../../assets/img/png/lightblue.png');
    padding-top: 1.5rem;
}

.side-nav ul {
    margin: 0;
    padding: 0;
}

.side-nav ul li a {
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
}